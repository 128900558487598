import React from 'react';
import Navigation from './Navigation';
import HeroImage from './hero-image.png'; // Replace with your actual image path
import Button from '@mui/material/Button';

const Home = () => {
  const primaryColor = '#766676';
  const lightColor = '#ffffff';
  const secondaryColor = '#83b100';

  return (
    <div>
      <div className="min-h-screen flex flex-col" style={{ backgroundColor: primaryColor, color: lightColor }}>
      <Navigation />
       {/* Hero Section */}
       <section className="relative h-[500px] bg-cover bg-center" style={{ backgroundImage: `url(${HeroImage})` }}>
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="absolute inset-0 flex flex-col justify-center items-center text-center text-white px-4">
            <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold mb-4 tracking-wide leading-tight" style={{ color: secondaryColor, textShadow: '2px 2px 4px rgba(0,0,0,0.3)' }}>
              Empower Your Health Journey
            </h1>
            <p className="text-xl md:text-2xl lg:text-3xl mb-8 leading-relaxed" style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.3)' }}>
              Take control of your well-being with our comprehensive health assessments.
            </p>
            <Button
              variant="contained"
              style={{ backgroundColor: secondaryColor, color: primaryColor, padding: '12px 24px', fontSize: '1.2rem', fontWeight: 'medium', borderRadius: '8px', transition: 'background-color 0.3s ease' }}
              className="hover:bg-lime-600"
            >
              Book a Discovery Call Now
            </Button>
          </div>
        </section>

        {/* About Us Section */}
        <section className="py-16 bg-gray-800 text-white">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-4xl font-bold mb-6" style={{ color: secondaryColor }}>
              About Us
            </h2>
            <div className="flex flex-col md:flex-row items-center justify-center items-center text-center">
              <div className="md:w-1/2 pr-8">
                <p className="text-lg leading-relaxed">
                  We are passionate about helping you achieve your health goals. Our team of experts... (your about us text)
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Services/Assessments Section */}
        <section className="py-16">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-4xl font-bold mb-6" style={{ color: secondaryColor }}>
              Our Assessments
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {/* Example Assessment Card 1 */}
              <div className="bg-white rounded-lg p-6 shadow-md" style={{ color: primaryColor }}>
                <h3 className="text-2xl font-semibold mb-4">Cardiovascular Health</h3>
                <p>Assess your heart health and identify potential risk factors.</p>
                <Button variant="contained" style={{ backgroundColor: secondaryColor, color: primaryColor, marginTop: '1rem' }}>
                  Learn More
                </Button>
              </div>
              {/* Example Assessment Card 2 */}
              <div className="bg-white rounded-lg p-6 shadow-md" style={{ color: primaryColor }}>
                <h3 className="text-2xl font-semibold mb-4">Nutrition Assessment</h3>
                <p>Analyze your dietary habits and get personalized recommendations.</p>
                <Button variant="contained" style={{ backgroundColor: secondaryColor, color: primaryColor, marginTop: '1rem' }}>
                  Learn More
                </Button>
              </div>
              {/* Example Assessment Card 3 */}
              <div className="bg-white rounded-lg p-6 shadow-md" style={{ color: primaryColor }}>
                <h3 className="text-2xl font-semibold mb-4">Stress Management</h3>
                <p>Evaluate your stress levels and discover coping strategies.</p>
                <Button variant="contained" style={{ backgroundColor: secondaryColor, color: primaryColor, marginTop: '1rem' }}>
                  Learn More
                </Button>
              </div>
            </div>
          </div>
        </section>

        {/* Testimonials Section (Optional) */}
        <section className="py-16 bg-gray-700 text-white">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-4xl font-bold mb-6" style={{ color: secondaryColor }}>
              Testimonials
            </h2>
            {/* Add your testimonials content here */}
          </div>
        </section>

        {/* Contact Us Section (Optional) */}
        <section className="py-16">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-4xl font-bold mb-6" style={{ color: secondaryColor }}>
              Contact Us
            </h2>
            {/* Add your contact form or information here */}
          </div>
        </section>

      </div>
    </div>
  );
};

export default Home;